import { FunctionComponent } from 'react'
import { Layout, Typography } from 'antd'

const { Text } = Typography

const { Footer: AntDFooter } = Layout

const Footer: FunctionComponent = () => {
  const currentYear: number = new Date().getFullYear()

  return (
    <AntDFooter style={{ textAlign: 'center' }}>
      <Text type='secondary'>&copy; The FootprintCalculator {currentYear}</Text>
    </AntDFooter>
  )
}

export default Footer
